import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./Home";
import Result from "./Result";

const PatientInformation = lazy(() => import("./PatientInformation"));
const SuccessCif = lazy(() => import("./SuccessCif"));
const Users = lazy(() => import("./Users"));
const NewUser = lazy(() => import("./NewUser"));
const LogIn = lazy(() => import("./LogIn"));
const UpdateUserProfile = lazy(() => import("./UpdateUserProfile"));
const ChangeUserPassword = lazy(() => import("./ChangeUserPassword"));
const ChangeUserProfile = lazy(() => import("./ChangeUserProfile"));
const CifList = lazy(() => import("./CifList"));
const Cif = lazy(() => import("./Cif"));
const AccessDenied = lazy(() => import("./AccessDenied"));
const LaboratoryInformation = lazy(() => import("./LaboratoryInformation"));
const Cdrs = lazy(() => import("./Cdrs"));
const Consent = lazy(() => import("./Consent"));
const PatientCif = lazy(() => import("./PatientCif"));
const ReferringFacility = lazy(() => import("./ReferringFacility"));
const checkAuth = (props) => {
  let isLoggedIn = false;
  const tokenid = localStorage.getItem("tokenid");
  console.log(tokenid);
  if (tokenid === null) {
    isLoggedIn = false;
  } else {
    isLoggedIn = true;
  }

  return isLoggedIn;
};
const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      )
    }
  />
);
const Routes = () => {
  return (
    <Switch>
      <Route exact strict path="/" component={Home} render={() => <Home />} />
      <Route
        strict
        path="/result/:id"
        component={Result}
        render={() => <Result />}
      />
      <Suspense
        fallback={
          <div className="text-center font-bold text-md">Loading page...</div>
        }
      >
        <Route
          exact
          strict
          path="/patientinformation"
          component={PatientInformation}
          render={() => <PatientInformation />}
        />

        <Route
          exact
          strict
          path="/consent"
          component={Consent}
          render={() => <Consent />}
        />

        <Route
          exact
          strict
          path="/successcif"
          component={SuccessCif}
          render={() => <SuccessCif />}
        />

        <AuthRoute
          exact
          strict
          path="/users"
          component={Users}
          render={() => <Users />}
        />

        <AuthRoute
          exact
          strict
          path="/newuser"
          component={NewUser}
          render={() => <NewUser />}
        />

        <AuthRoute
          exact
          strict
          path="/updateuserprofile"
          component={UpdateUserProfile}
          render={() => <UpdateUserProfile />}
        />

        <AuthRoute
          exact
          strict
          path="/changeuserpassword"
          component={ChangeUserPassword}
          render={() => <ChangeUserPassword />}
        />

        <AuthRoute
          exact
          strict
          path="/changeuserprofile"
          component={ChangeUserProfile}
          render={() => <ChangeUserProfile />}
        />

        <AuthRoute
          exact
          strict
          path="/ciflist"
          component={CifList}
          render={() => <CifList />}
        />

        <Route
          exact
          strict
          path="/cif"
          component={Cif}
          render={() => <Cif />}
        />

        <AuthRoute
          exact
          strict
          path="/laboratoryinformation"
          component={LaboratoryInformation}
          render={() => <LaboratoryInformation />}
        />

        <AuthRoute
          exact
          strict
          path="/cdrs"
          component={Cdrs}
          render={() => <Cdrs />}
        />

        <Route
          exact
          strict
          path="/login"
          component={LogIn}
          render={() => <LogIn />}
        />

        <Route
          exact
          strict
          path="/accessdenied"
          component={AccessDenied}
          render={() => <AccessDenied />}
        />
        <Route
          exact
          strict
          path="/patientcif"
          component={PatientCif}
          render={() => <PatientCif />}
        />
        <AuthRoute
          exact
          strict
          path="/referringfacility"
          component={ReferringFacility}
          render={() => <ReferringFacility />}
        />
      </Suspense>
    </Switch>
  );
};

export default Routes;
