/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import logo from "../assets/hospitallogo.png";
import { message, Row, Col, Avatar, Image } from "antd";
import axios from "axios";
import appSettings from "../assets/appSettings";
import moment from "moment";
export default function Result(props) {
  const [result, setResult] = useState({});
  const [withResult, setWithResult] = useState(false);
  const handleGetResultDetails = async () => {
    const id = props.match.params.id;
    axios.defaults.headers.common["Authorization"] = "Bearer ";
    try {
      try {
        let res = await axios.get(appSettings.apiUrl + "GetResult", {
          params: {
            id: id,
          },
        });
        if (res) {
          if (res.data.stat.status === 1) {
            setWithResult(true);
            setResult(res.data.result);
          } else {
            setResult({});
          }
        }
      } catch (error) {
        return error.message;
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    handleGetResultDetails();
  }, []);
  return (
    <div>
      <Row>
        <Col sm={24} xs={24} md={6}></Col>
        <Col sm={24} xs={24} md={12}>
          <img src={logo} alt="Logo" width="100%" height="600" />
          <div className="shadow-lg bg-gray-100 p-4">
            {withResult && (
              <>
                <Row gutter={[8, 8]}>
                  <Col sm={24} xs={24} md={4} className="font-bold">
                    Ascession No.:
                  </Col>
                  <Col className="font-bold">{result.AccessionNo}</Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col sm={24} xs={24} md={4} className="font-bold">
                    Patient Name:
                  </Col>
                  <Col className="font-bold">{result.name}</Col>
                </Row>

                <Row gutter={[8, 8]}>
                  <Col sm={24} xs={24} md={4} className="font-bold">
                    Specimen Date:
                  </Col>
                  <Col className="font-bold">
                    {moment(result.DateReceived).format("MM/DD/YYYY")}
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col sm={24} xs={24} md={4} className="font-bold">
                    Requisitioner:
                  </Col>
                  <Col className="font-bold">{result.Requisitioner}</Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col sm={24} xs={24} md={4} className="font-bold">
                    Facility:
                  </Col>
                  <Col className="font-bold">{result.Institution}</Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col sm={24} xs={24} md={4} className="font-bold">
                    Result:
                  </Col>
                  <Col className="font-bold">{result.PCR_Result}</Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col sm={24} xs={24} md={4} className="font-bold">
                    Result Date:
                  </Col>
                  <Col className="font-bold">
                    {moment(result.DateReleased).format("MM/DD/YYYY")}
                  </Col>
                </Row>
              </>
            )}

            {!withResult && (
              <div className="text-center font-extrabold text-red-700">
                RESULT NOT FOUND!
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
